<template>
  <div class="ai-inputs">
    <div :class="['ai-operations', { firstTips: isFirstTip && !showMagic }]">
      <input class="ai-input" name="lyrics" type="text" maxlength="20" :style="highActiveStyle" :placeholder="tips"
        :disabled="!canInput" :value="currentValue" @input="handleInput" autocomplete="off" @change="handleChange" />
      <!--高亮显示-->
      <div v-show="highText" class="ai-input-high" v-html="highText"></div>
      <ul :class="['operations', { active: isActive }]">
        <li ref="promptItem" class="operation operation-prompt" :title="$t('aiCreate.aiTips')"
          @click="handleDialogPrompt"></li>
        <li ref="changeItem" class="operation operation-change" :title="$t('aiCreate.changeWord')"
          @click="handleDialogChange"></li>
        <li ref="magicItem" :class="['operation', 'operation-magic', { disable: !showMagic }]"
          :title="$t('aiCreate.magicWord')" @click="handleDialogMagic"></li>
      </ul>
    </div>
    <div class="ai-btn ai-add" @click="handleOperation('add')"></div>
    <div class="ai-btn" @click="handleOperation('less')"></div>
    <div :style="dialogPromptStyle" class="ai-dialog ai-dialog-words-1" v-click-outside="onClickOutsidePrompt">
      <h3 class="ai-dialog-title">
        {{ $t("aiCreate.aiWords") }}
        <span v-show="!wordsObj.loading && wordsObj.pages > 1" :class="['prev', { disabled: wordsObj.page === 1 }]"
          @click="prevPage">
        </span>
        <span v-show="!wordsObj.loading && wordsObj.pages > 1"
          :class="['next', { disabled: wordsObj.page === wordsObj.pages }]" @click="nextPage">
        </span>
      </h3>
      <div ref="wordsContainer" class="items-container">
        <ul v-show="!wordsObj.loading" ref="wordsItems" class="ai-dialog-content items">
          <li v-for="(item, index) in wordsObj.list" :key="index"
            :class="['item', { active: index === wordsObj.activeIdx }]" @click="handleWordsClick(index)">
            <em></em><span :title="item">{{ item }}</span>
          </li>
        </ul>
        <div class="magic-lists-loading el-flag" v-show="wordsObj.loading" style="transform:translateY(0)">
          <div class="ajax-loading"></div>

        </div>
      </div>
    </div>
    <div :style="[dialogChangeStyle]" class="ai-dialog ai-dialog-words" v-click-outside="onClickOutsideChange">
      <h3 class="ai-dialog-title">
        {{ $t("aiCreate.aiChange") }}
        <span v-show="changeWords.prevWord" :style="{
  backgroundImage: `url(${require('@/assets/images/reset_bg.png')})`,
  padding: `0 0.13rem`,
  backgroundSize: '100% 100%',
}" class="next" @click="resetPrevWords">{{ $t("aiCreate.reset") }}</span>
      </h3>
      <div class="ai-dialog-content change-words">
        <h4>{{ $t('aiCreate.changeTips') }}</h4>
        <dl class="btns">
          <dt v-for="(item, index) in changeWords.cutWords" :key="index" :class="[
  'btn',
  { active: changeWords.cutIdx === index },
  { isnull: item.trim() === '' },
]" @click="handleCutWord(item, index)">
            {{ item }}
          </dt>
        </dl>
        <h4 v-show="changeWords.replaceWords.length > 0">
          {{ $t('aiCreate.replace') }}
        </h4>
        <dl class="btns">
          <dt v-for="(item, index) in changeWords.replaceWords" :key="index"
            :class="['btn', { active: changeWords.replaceIdx === index }]" @click="handleReplaceWord(item, index)">
            {{ item }}
          </dt>
        </dl>
      </div>
    </div>
    <div :style="dialogMagicStyle" class="ai-dialog ai-dialog-words el-flag" v-click-outside="onClickOutsideMagic">
      <h3 class="ai-dialog-title el-flag">
        {{ $t("aiCreate.aiMagic") }}
        <span v-show="magicWords.prevWord" :style="{
  backgroundImage: `url(${require('@/assets/images/reset_bg.png')})`,
  padding: `0 0.13rem`,
  backgroundSize: '100% 100%',
}" class="next el-flag" @click="resetMagicWord">{{ $t("aiCreate.reset") }}</span>
      </h3>
      <div class="ai-dialog-content magic-words el-flag">
        <div class="magic-radios el-flag">
          <div :class="['magic-radio', { active: magicWords.magicIdx == 0 }, 'el-flag']" @click="handleMagicType(0)">
            <em class="el-flag"></em><span class="el-flag">{{ $t("aiCreate.single") }}</span>
          </div>
          <div :class="['magic-radio', { active: magicWords.magicIdx == 1 }, 'el-flag']" @click="handleMagicType(1)">
            <em class="el-flag"></em><span class="el-flag">{{ $t("aiCreate.double") }}</span>
          </div>
        </div>
        <div v-show="!magicWords.loading" class="magic-lists el-flag">
          <button v-for="(item, index) in magicWords.words" :key="index"
            :class="['magic-btn', { active: magicWords.idx === index }, 'el-flag']"
            @click="handleMagicWords(item, index)">
            {{ item }}
          </button>
        </div>
        <div v-show="magicWords.loading" class="magic-lists-loading el-flag">
          <div class="ajax-loading el-flag"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { GetChangeWords, GetDynamicLyrics, GetWords, GetMagicWords, GetFirstLyrics, GetDirty } from "@/api/create";
const DIALOG_HEIGHT = 249;
const DIALOG_PAD = 6;
export default {
  name: 'HaiCreate',
  props: {
    tips: {
      type: String,
      default: ''
    },
    value: {
      type: String,
      default: ''
    },
    id: {
      type: String,
      default: -1,
    },
    idx: {
      type: Number,
      default: -1
    },
    finishLen: {
      type: Number,
      default: 0
    },
    finishSentence: {
      type: Array,
      default() {
        return []
      }
    },
    isFirst: {
      type: Boolean,
      default: true,
    },
    highIdx: {
      type: Object,
      default() {
        return {
          idx: -1,
          timestamp: 0
        }
      }
    },
    isCludeNull: {
      type: Boolean,
      default: false
    },
    currentSentences: {
      type: Array,
      default() {
        return []
      }
    }
  },
  watch: {
    value() {
      this.currentValue = this.value
    },
    highIdx(val) {
      if (val.idx !== -1 && val.idx === this.idx) {
        const len = this.currentValue.length
        const prefix = this.currentValue.slice(0, len - 2)
        const suffix = this.currentValue.slice(-2)
        this.highText = `${prefix}<span style="color: rgba(104, 131, 239, 1)">${suffix}</span>`
        this.highActiveStyle = {
          color: 'transparent'
        }
      }
    },
    currentSentences() {
      if (this.idx !== 0) {
        let arrs = JSON.parse(JSON.stringify(this.currentSentences))
        for (let i = 0; i < this.idx; i++) {
          if (arrs[i].trim() === '') return (this.isPreNull = true)
        }
      }
      this.isPreNull = false
    }
  },
  data() {
    return {
      dialogPromptStyle: { display: 'none' },
      dialogChangeStyle: { display: 'none' },
      dialogMagicStyle: { display: 'none' },
      currentValue: this.value,
      style: '',
      canInput: true,
      canChange: true,
      canAI: true,
      canYa: true,
      wordsObj: {
        activeIdx: -1,
        total: 8,
        pages: 2,
        page: 1,
        list: [],
        loading: false
      },
      changeWords: {
        cutWords: [],
        replaceWords: [],
        cutIdx: -1,
        replaceIdx: -1,
        positionWord: '',
        prevWord: '',
        nextWord: '',
        wordPosition: [0, 0]
      },
      magicWords: {
        words: [],
        magicIdx: -1, // 单押 还是 双押韵
        idx: -1,
        positionWord: '',
        prevWord: '',
        loading: false
      },
      highText: '',
      highActiveStyle: {},
      isFirstTip: false,
      ftSize: 0,
      isPreNull: false
    }
  },
  computed: {
    rt() {
      return this.ftSize / 100
    },
    isActive() {
      return (
        this.dialogPromptStyle.position === 'fixed' ||
        this.dialogMagicStyle.position === 'fixed' ||
        this.dialogChangeStyle.position === 'fixed'
      )
    },
    showMagic() {
      if (this.idx === 0) return false
      if (this.canYa == false) return false
      if (this.isCludeNull) return false
      if (!this.canInput) return false
      if (!this.currentValue) return false
      return true
    }
  },
  mounted() {
    window.addEventListener("resize", () => {
      this.ftSize = parseInt(document.documentElement.style.fontSize);
    });
    console.log(this.showMagic, this.isFirst, this.id);
    this.ftSize = parseInt(document.documentElement.style.fontSize);


    this.style = this.$route.query.style

    if (this.showMagic == false) {
      // 第一行
      this.isFirstTip = true
      this.$emit('on-first-show', true)
      if (this.isFirstTip) {
        let timer = setTimeout(() => {
          this.isFirstTip = false
          this.$emit('on-first-show', false)
          clearTimeout(timer)
        }, 3000)
      }
    }
  },
  methods: {
    handleWordsClick(idx) {
      this.wordsObj.activeIdx = idx
      this.$emit('on-ai-select', {
        value: this.wordsObj.list[idx],
        index: this.idx
      })
      // this.dialogPromptStyle = { display: 'none' }
    },
    prevPage() {
      if (this.wordsObj.page === 1) {
        this.$message({
          text: this.$t('aiCreate.errPrevPage')
        })
        return
      }
      this.wordsObj.page--
      this.transPage()
    },
    nextPage() {
      if (this.wordsObj.page === this.wordsObj.pages) {
        this.$message({
          text: this.$t('aiCreate.errNextPage')
        })
        return
      }
      this.wordsObj.page++
      this.transPage()
    },
    transPage() {
      const h = this.$refs.wordsContainer.clientHeight
      const rate = parseInt(document.documentElement.style.fontSize) / 100
      const dis = -(this.wordsObj.page - 1) * (h - 36 * rate) // 36 paddingTop
      this.$refs.wordsItems.style.transform = `translateY(${dis}px)`
      this.$refs.wordsItems.style.transition = `transform 0.2s ease-in`
    },
    handleInput(e) {
      this.$emit('on-input', { event: e, index: this.idx })
    },
    handleChange(e) {
      // console.log(e.target.value)
      // this.$emit('on-change', { event: e, index: this.idx })
      const o = e.target
      e.target.value = o.value.replace(/[^\u4E00-\u9FA5]/g, '')
      if (e.target.value === '') {
        this.$emit('on-input', { event: e, index: this.idx })
        return
      }
      GetDirty({
        sen: [e.target.value]
      }).then(res => {
        if (res.code === 200 && res.data !== '') {
          this.$message({
            text: this.$t('存在敏感词汇')
          })
          e.target.value = res.data
          this.$emit('on-input', { event: e, index: this.idx })
          // this.currentValue = res.data
        } else {
          this.$emit('on-input', { event: e, index: this.idx })
        }
      });
    },
    handleOperation(type) {
      this.$emit('on-operation', { type, index: this.idx })
    },
    // ai提示
    handleDialogPrompt() {
      if (this.canAI == false) {
        return
      }
      if (this.idx !== 0 && this.isPreNull) {
        this.$message({
          text: this.$t('aiCreate.needFinishPrev')
        })
        return
      }
      if (!this.canInput) {
        this.$message({
          text: this.$t('aiCreate.finnishPrev')
        })
        return
      }
      this.closeAllDialog()

      const magicItem = this.$refs.magicItem.getBoundingClientRect()
      const fixedX = magicItem.x + magicItem.width + 10
      const fixedY = magicItem.y + magicItem.height - DIALOG_HEIGHT / 2
      console.log(this.idx)
      this.dialogPromptStyle = {
        position: 'fixed',
        top: fixedY + 'px',
        left: fixedX + 'px'
      }
      let params = {
        mode_id: this.id,
        // style: this.style,
        sentense: this.finishSentence.slice(0, this.finishLen + this.idx)
      };
      // pm:删除自动补全
      // params.sentense.push(this.currentValue)
      params.sentense.push('')

      this.wordsObj.loading = true
      this.canAI = false

      if (this.isFirst == true) {
        GetFirstLyrics(params).then((res) => {
          if(res.code == 200){
            let opt = res.data?.options || []
            this.wordsObj.list = opt;
            this.wordsObj.activeIdx = -1;
            this.wordsObj.total = opt.length + 1;
            this.wordsObj.page = 1;
            this.$refs.wordsItems.style.transform = `translateY(0)`;
            this.wordsObj.pages = Math.ceil(this.wordsObj.total / 4);
  
            this.wordsObj.loading = false;
          }
        });
      } else {
        GetDynamicLyrics(params).then((res) => {
          if(res.code == 200){
            let opt = res.data?.options || []
            this.wordsObj.list = opt
            this.wordsObj.activeIdx = -1;
            this.wordsObj.total = opt.length + 1 || 0;
            this.wordsObj.page = 1;
            this.$refs.wordsItems.style.transform = `translateY(0)`;
            this.wordsObj.pages = Math.ceil(this.wordsObj.total / 4);
  
            this.wordsObj.loading = false;
          }
        });
      }
    },
    // 换词
    handleDialogChange() {
      if (this.canChange == false) {
        return
      }
      if (!this.currentValue) {
        this.$message({
          text: this.$t('aiCreate.input')
        })
        return
      }

      this.closeAllDialog()

      const magicItem = this.$refs.magicItem.getBoundingClientRect()
      const fixedX = magicItem.x + magicItem.width + 10
      const fixedY = magicItem.y + magicItem.height - DIALOG_HEIGHT / 2

      // if (this.idx > 1) {
      //   fixedY = pos.y - DIALOG_HEIGHT * this.rt + DIALOG_PAD * this.rt
      // }

      this.$nextTick(() => {
        this.dialogChangeStyle = {
          position: 'fixed',
          top: fixedY + 'px',
          left: fixedX + 'px'
        }
      })
      this.changeWords.cutIdx = -1
      this.changeWords.replaceIdx = -1
      this.changeWords.positionWord = ''
      this.changeWords.prevWord = ''
      this.changeWords.cutWords = []
      this.changeWords.replaceWords = []

      this.canChange = false
      // 切词
      GetWords({ lyric: this.currentValue, mode_id: this.id }).then((res) => {
        console.log("fcaa", res.data.segment_word);
        if (res.code == 200) {
          this.changeWords.cutWords = res.data?.segment_word;
        }
      });
    },
    // 获取要换的词
    handleCutWord(word, index) {
      this.changeWords.cutIdx = index;
      this.changeWords.replaceIdx = -1;
      console.log(this.changeWords.cutWords);
      const len = this.changeWords.cutWords.reduce((total, current, idx) => {
        total += idx < index ? current.length : 0
        return total
      }, 0)
      let params = {
        mode_id: this.id,
        lyric: this.finishSentence,
        position: [this.finishLen + this.idx, len, word.length],
      };
      this.changeWords.wordPosition = [len, word.length];
      this.changeWords.positionWord = word;
      GetChangeWords(params).then((res) => {
        if (res.code == 200) {
          this.changeWords.replaceWords = res.data?.candi_words;
          // this.changeWords.cutWords = 
        }
      });
    },
    // 替换
    handleReplaceWord(item, index) {
      let val = this.currentValue
      let start =
        this.changeWords.wordPosition[0] + this.changeWords.wordPosition[1]
      let prefix = this.currentValue.slice(0, this.changeWords.wordPosition[0])
      let suffix = this.currentValue.slice(start)
      val = prefix + item + suffix
      this.changeWords.replaceIdx = index
      this.$emit('on-ai-select', { value: val, index: this.idx })
      this.changeWords.prevWord = item
    },
    // 重置
    resetPrevWords() {
      this.changeWords.replaceWords = []
      let val = this.currentValue
      // 修改为重置所有词语
      val = this.changeWords.cutWords.join('')
      this.$emit('on-ai-select', { value: val, index: this.idx })

      this.changeWords.cutIdx = -1
      this.changeWords.replaceIdx = -1
      this.changeWords.prevWord = ''
    },
    // 押韵
    handleDialogMagic() {
      if (this.idx === 0) return
      if (this.canYa == false) {
        return
      }
      this.closeAllDialog()
      if (this.isPreNull) {
        this.$message({
          text: this.$t('aiCreate.needFinishPrev')
        })
        return
      }

      if (!this.canInput) {
        this.$message({
          text: this.$t('aiCreate.needFinishPrev')
        })
        return
      }
      console.log('fcasd')
      if (!this.currentValue) {
        this.$message({
          text: this.$t('aiCreate.needFinishCur')
        })
        return
      }

      const magicItem = this.$refs.magicItem.getBoundingClientRect()
      const fixedX = magicItem.x + magicItem.width + 10
      const fixedY = magicItem.y + magicItem.height - DIALOG_HEIGHT / 2

      this.dialogMagicStyle = {
        position: 'fixed',
        top: fixedY + 'px',
        left: fixedX + 'px'
      }
      // this.showMagic = false
      this.canYa = false
      setTimeout(() => {
        this.handleMagicType(0)
      }, 100)
    },
    handleMagicType(index) {
      this.$emit('on-high-magic', { index: this.idx })
      this.magicWords.magicIdx = index
      this.magicWords.idx = ''
      let params = {
        mode_id: this.id,
        lyric: this.finishSentence,
        rhyme_idx: this.idx + this.finishLen,
        dual_rhyme: index === 1, // true 双押，false 单押
      };
      this.magicWords.loading = true;
      GetMagicWords(params).then((res) => {
        if(res.code == 200){
          let result = res.data?.rhyme_words || {};
          this.magicWords.words = result.words;
          this.magicWords.loading = false;
        }
      });
    },
    handleMagicWords(item, index) {
      console.log(this.idx)
      if (this.idx < 1) return // 第一行不能押韵
      this.magicWords.idx = index

      const len = this.currentValue.length
      this.magicWords.positionWord = this.currentValue.slice(-item.length)
      const pWordLen = this.magicWords.positionWord.length
      const prefixVal = this.currentValue.slice(0, len - pWordLen)
      let val = prefixVal + item

      this.$emit('on-ai-select', { value: val, index: this.idx })

      this.magicWords.prevWord = item
    },
    resetMagicWord() {
      let val = this.currentValue
      let len = this.magicWords.positionWord.length
      let valLen = this.currentValue.length
      val = val.slice(0, valLen - len) + this.magicWords.positionWord
      this.$emit('on-ai-select', { value: val, index: this.idx })

      this.magicWords.idx = -1
      this.magicWords.prevWord = ''
    },
    // 关闭所有dialog
    closeAllDialog() {
      const $dialogs = Array.from(document.querySelectorAll('.ai-dialog'))
      $dialogs.forEach(dom => {
        dom.style.display = 'none'
      })
    },
    // 检查是否触底
    checkIsBottomOut(top) {
      const screenH = document.documentElement.clientHeight
      return top + DIALOG_HEIGHT > screenH
    },
    // 关闭 提示
    onClickOutsidePrompt(e) {
      if (!e.target.classList.contains('operation-prompt')) {
        this.dialogPromptStyle = {
          display: 'none'
        }

        this.canAI = true
      }
    },
    // 关闭 换词
    onClickOutsideChange(e) {
      if (!e.target.classList.contains('operation-change')) {
        this.dialogChangeStyle = {
          display: 'none'
        }

        this.canChange = true
      }
    },
    onClickOutsideMagic(e) {
      if (e.target.classList.contains('operation-magic') == false) {
        this.dialogMagicStyle = {
          display: 'none'
        }

        this.canYa = true
      }
      if (!e.target.classList.contains('el-flag')) {
        this.highText = ''
        this.highActiveStyle = {}
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.ai-inputs {
  display: flex;
  display: -webkit-flex;
  align-items: center;

  &:not(:last-child) {
    margin-bottom: 62px;
  }

  &:last-child {
    margin-bottom: 0 !important;
  }

  .ai-btn {
    width: 0.28rem;
    height: 0.28rem;
    border-radius: 50%;
    position: relative;
    cursor: pointer;
    background: url("~@/assets/images/less-btn.png") no-repeat center / contain;

    &.ai-add {
      margin: 0 0.14rem 0 0.22rem;
      background: url('~@/assets/images/add-btn.png') no-repeat center / contain;
    }
  }

  .ai-operations {
    width: 7.02rem;
    height: 0.38rem;
    // border: 0.01rem solid rgba(104, 231, 237, 1);

    box-shadow: 0px 0px 9px 0px #858d9c inset;

    background: rgba(133, 141, 156, 0.42);
    padding: 0 1rem 0 0.4rem;

    position: relative;

    .ai-input {
      display: block;
      width: 4.6rem;
      height: 100%;
      border: none;
      border-radius: 0.25rem;
      background-color: transparent;

      color: #939aaa;
      font-size: 0.16rem;
      padding-left: 0.35rem;
      color: #3a577d;
    }

    .ai-input-high {
      width: 4.6rem;
      // height: 0.5rem;
      // line-height: 0.5rem;
      background-color: transparent;
      padding-left: 0.24rem;
      position: absolute;
      left: 0.4rem;
      top: 50%;
      transform: translateY(-50%);
      z-index: 10;
      font-size: 0.2rem;
      font-weight: 400;
      color: rgba(104, 231, 237, 1);

      span {
        font-size: 0.2rem;
        font-weight: 400;
        color: rgba(104, 131, 239, 1);
      }
    }

    &:hover {
      .operations {
        display: block;
      }

      .ai-input-high {
        display: none;
      }

      .ai-input {
        color: #3a577d !important;
      }
    }

    &.firstTips {
      .operations {
        display: block;
      }
    }

    .operations {
      width: 1.24rem;
      height: 0.48rem;
      line-height: 0.48rem;
      position: absolute;
      top: 0;
      right: 0;
      display: none;

      &.active {
        display: block;
      }

      .operation {
        width: 0.16rem;
        height: 0.48rem;
        padding: 0.16rem 0;
        display: block;
        cursor: pointer;
        margin-right: 0.18rem;
        color: #fff;
        float: left;
        position: relative;

        &.operation-prompt {
          background: url("~@/assets/images/prompt.png") no-repeat center / 0.16rem 0.16rem;
        }

        &.operation-change {
          background: url("~@/assets/images/change.png") no-repeat center / 0.16rem 0.16rem;
        }

        &.operation-magic {
          background: url("~@/assets/images/magic.png") no-repeat center / 0.16rem 0.16rem;

          &.disable {
            background: url("~@/assets/images/magic.png") no-repeat center / 0.16rem 0.16rem;
            opacity: 0.5;
          }
        }

        &:hover {
          .ai-dialog {
            display: block;
          }
        }
      }
    }
  }

  .ai-dialog.ai-dialog-words-1 {
    span {
      display: block;
      height: 0.25rem;
      font-size: 0.18rem;
      font-weight: 400;
      color: rgba(255, 255, 255, 1);
      line-height: 0.25rem;
      // text-decoration: underline;
      position: absolute;
      cursor: pointer;
      white-space: nowrap;

      &.disabled {
        opacity: 0.5;
        cursor: not-allowed;
      }

      &.prev {
        transform: rotate(180deg);
        width: 22px;
        height: 25px;
        top: 0.12rem;
        left: 0.2rem;
        background: url('~@/assets/images/next.png') no-repeat center / 0.22rem
          0.25rem;
      }

      &.next {
        width: 22px;
        height: 25px;
        top: 0.12rem;
        right: 0.2rem;
        background: url('~@/assets/images/next.png') no-repeat center / 0.22rem
          0.25rem;
      }
    }
  }

  .ai-dialog {
    width: 3.9rem;
    // height: 2.49rem;
    background: #fff;
    border-radius: 0.08rem;
    box-shadow: 0 0 6px 0px rgba(0, 0, 0, 0.5);
    z-index: 100;

    &::-webkit-scrollbar {
      width: 10px;
      height: 8px;
      background-color: transparent;
    }

    &::-webkit-scrollbar-track {
      border-radius: 4px;
      background-color: transparent;
    }

    &::-webkit-scrollbar-thumb {
      background: #aebdd8;
      border: 1px solid #cfcfcf;
      border-radius: 4px;
    }

    .ai-dialog-title {
      width: 100%;
      height: 0.48rem;
      background: #edf2f6;
      border-radius: 0.08rem 0.08rem 0 0;
      font-size: 0.24rem;
      font-weight: 600;

      color: #3a577d;
      line-height: 0.48rem;
      text-align: center;
      position: relative;

      >span {
        display: block;
        height: 0.25rem;
        font-size: 0.12rem;
        font-weight: 400;
        color: rgba(255, 255, 255, 1);
        line-height: 0.25rem;
        // text-decoration: underline;
        position: absolute;
        cursor: pointer;
        white-space: nowrap;
        top: 0.12rem;
        right: 0.2rem;

        &.disabled {
          opacity: 0.5;
          cursor: not-allowed;
        }

        // &.prev {
        //   transform: rotate(180deg);
        //   width: 22px;
        //   height: 25px;
        //   top: 0.12rem;
        //   left: 0.2rem;
        //   background: url('~@/assets/images/next.png') no-repeat center /
        //     0.22rem 0.25rem;
        // }
        // &.next {
        //   width: 22px;
        //   height: 25px;
        //   top: 0.12rem;
        //   right: 0.2rem;
        //   background: url('~@/assets/images/next.png') no-repeat center /
        //     0.22rem 0.25rem;
        // }
      }
    }

    .items-container {
      // height: 2.9rem;
      overflow: hidden;
    }

    .magic-lists-loading {
      transform: translateY(-0.3rem);
      height: 1.2rem;
      overflow: hidden;
    }

    .ajax-loading {
      width: 100%;
      height: 100%;
      background: url('~@/assets/images/loading.gif') no-repeat center / 0.5rem
        0.5rem;
    }

    .ai-dialog-content {
      &.items {
        display: -webkit-flex;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 4.2rem;
        // height: 100%;
        height: 2rem;

        // overflow-y: hidden;
        .item {
          width: 3.8rem;
          flex: 0 0 36px;
          height: 36px;
          display: -webkit-flex;
          display: flex;
          flex-wrap: nowrap;
          align-items: center;
          padding-left: 37px;

          &:not(:last-child) {
            margin-bottom: 0.18rem;
          }

          &.active {
            background: url("~@/assets/images/li_bg.png") no-repeat center / 100%;

            >em {
              background: url("~@/assets/images/right_icon.png") no-repeat center / contain;
              border: none;
            }

            >span {
              color: #3a577d;
            }
          }

          >em {
            width: 0.09rem;
            height: 0.16rem;
            /*background: rgba(201, 201, 201, 1);*/
            // border: 0.01rem solid #497491;
            // margin-right: 0.16rem;
            border-radius: 50%;
            cursor: pointer;
            background: url('~@/assets/images/right_icon.png') no-repeat center /
              contain;
          }

          >span {
            // width: 3.79rem;
            height: 0.28rem;
            font-size: 0.16rem;
            font-weight: 500;
            padding-left: 30px;
            color: #939ba5;
            line-height: 0.28rem;
            overflow: hidden;
            white-space: nowrap;
            display: block;
            cursor: pointer;
          }
        }
      }

      &.change-words {
        padding: 0.2rem 0 0.3rem 0.18rem;

        >h4 {
          /*width: 1.96rem;*/
          height: 0.22rem;
          font-size: 0.16rem;
          font-weight: 400;
          color: #3a577d;
          line-height: 0.22rem;
        }

        .btns {
          display: -webkit-flex;
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          line-height: normal;
          margin: 0.1rem 0 0.2rem;

          .btn {
            padding: 0 0.2rem;
            display: inline-block;
            // height: 0.26rem;
            // line-height: 0.26rem;
            background: url('~@/assets/images/btnlg_bg.png') no-repeat;
            background-size: 100% 100%;
            border-radius: 0.06rem;
            margin-top: 0.1rem;
            color: #fff;
            margin-right: 0.05rem;
            cursor: pointer;
            font-size: 0.15rem;

            &.active {
              background: url('~@/assets/images/btnlg0_bg.png') no-repeat;
              background-size: 100% 100%;

              color: #3a577d;
            }

            &.isnull {
              width: 0;
              padding: 0;
              margin-right: 0;
            }
          }
        }
      }

      &.magic-words {
        padding: 0.32rem 0.2rem;

        .magic-radios {
          margin-bottom: 0.3rem;
          display: -webkit-flex;
          display: flex;
          align-items: center;

          .magic-radio {
            cursor: pointer;
            margin-right: 0.3rem;
            display: -webkit-flex;
            display: flex;
            align-items: center;

            >em {
              width: 0.09rem;
              height: 0.14rem;
              display: block;
              background: url("~@/assets/images/right_icon.png") no-repeat center / contain;
            }

            &.active {
              >em {}

              span {
                color: #3a577d;
              }
            }

            >span {
              height: 0.3rem;
              font-size: 0.16rem;
              font-weight: 400;
              white-space: nowrap;
              color: rgba(58, 87, 125, 0.5);
              line-height: 0.3rem;
              margin-left: 0.04rem;
              display: block;
            }
          }
        }

        .magic-lists {
          font-size: 0;

          .magic-btn {
            height: 0.38rem;
            padding: 0 0.2rem;
            font-size: 0.15rem;
            font-weight: 400;
            color: #fff;
            line-height: 0.38rem;
            margin-right: 0.04rem;
            background: url('~@/assets/images/btnlg_bg.png') no-repeat;
            background-size: 100% 100%;
            border-radius: 0.08rem;
            outline: none;
            border: none;
            cursor: pointer;
            margin-bottom: 0.2rem;

            &.active {
              background: url('~@/assets/images/btnlg0_bg.png') no-repeat;
              background-size: 100% 100%;

              color: #3a577d;
            }
          }
        }
      }
    }
  }
}
</style>
